import { NextPage } from 'next';
import Link from 'next/link';


import Layout from '../components/Layout';

import styles from '../styles/pages/Error.module.scss'


const Error404: NextPage = () => {
    return (
        <Layout>
            <div className={styles.title}>Pagina non trovata</div>

            <div className={styles.linkContainer}>
                <Link href="/">
                    <a>Acquista di nuovo</a>
                </Link>
            </div>
        </Layout>
    );
}


export default Error404